export default {
  // Options.jsx
  items_per_page: '/ rûpel',
  jump_to: 'Biçe',
  jump_to_confirm: 'piştrast bike',
  page: '',

  // Pagination.jsx
  prev_page: 'Rûpelê Pêş',
  next_page: 'Rûpelê Paş',
  prev_5: '5 Rûpelên Pêş',
  next_5: '5 Rûpelên Paş',
  prev_3: '3 Rûpelên Pêş',
  next_3: '3 Rûpelên Paş',
};
