export default {
  // Options.jsx
  items_per_page: '/ sayfa',
  jump_to: 'Git',
  jump_to_confirm: 'onayla',
  page: '',

  // Pagination.jsx
  prev_page: 'Önceki Sayfa',
  next_page: 'Sonraki Sayfa',
  prev_5: 'Önceki 5 Sayfa',
  next_5: 'Sonraki 5 Sayfa',
  prev_3: 'Önceki 3 Sayfa',
  next_3: 'Sonraki 3 Sayfa',
};
