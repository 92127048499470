export default {
  // Options.jsx
  items_per_page: '/ str',
  jump_to: 'Idi na',
  jump_to_confirm: 'potvrdi',
  page: '',

  // Pagination.jsx
  prev_page: 'Prijašnja stranica',
  next_page: 'Sljedeća stranica',
  prev_5: 'Prijašnjih 5 stranica',
  next_5: 'Sljedećih 5 stranica',
  prev_3: 'Prijašnje 3 stranice',
  next_3: 'Sljedeće 3 stranice',
};
