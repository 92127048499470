export default {
  // Options.jsx
  items_per_page: '/ strani',
  jump_to: 'Pojdi na',
  jump_to_confirm: 'potrdi',
  page: '',

  // Pagination.jsx
  prev_page: 'Prejšnja stran',
  next_page: 'Naslednja stran',
  prev_5: 'Prejšnjih 5 strani',
  next_5: 'Naslednjih 5 strani',
  prev_3: 'Prejšnje 3 strani',
  next_3: 'Naslednje 3 strani',
};
