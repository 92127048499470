export default {
  today: 'I dag',
  now: 'Nu',
  backToToday: 'Gå til i dag',
  ok: 'Ok',
  clear: 'Annuller',
  month: 'Måned',
  year: 'År',
  timeSelect: 'Vælg tidspunkt',
  dateSelect: 'Vælg dato',
  monthSelect: 'Vælg måned',
  yearSelect: 'Vælg år',
  decadeSelect: 'Vælg årti',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Forrige måned(PageUp)',
  nextMonth: 'Næste måned (PageDown)',
  previousYear: 'Forrige år (Control + left)',
  nextYear: 'Næste r (Control + right)',
  previousDecade: 'Forrige årti',
  nextDecade: 'Næste årti',
  previousCentury: 'Forrige århundrede',
  nextCentury: 'Næste århundrede',
};
